

























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class AppBottomNav extends Vue {
    @Prop({ required: true }) readonly routes!: Array<object>;
}
