


















































































































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AppSideNav extends Vue {
    chosenSideSanck = false;

    @Prop({ required: true }) readonly routes!: Array<object>;

    // Is mobile or not
    get mobile(): boolean {
        return this.$vuetify.breakpoint.smAndDown;
	}

    // Is theme dark active?
    get isThemeDark(): boolean {
		return this.$vuetify.theme.dark;
	}

    get themeIcon(): string {
        return `mdi-${this.isThemeDark ? 'moon-waning-crescent' : 'white-balance-sunny'}`
    }

    get themeIconColor(): string {
        return this.isThemeDark ? '' : 'yellow darken-3';
    }

    toggleTheme(): void {
        this.chosenSideSanck = true;
		this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        localStorage.setItem('light', (!this.$vuetify.theme.dark).toString());
        this.$analytics.logEvent("toggle_theme", {
            'dark': this.$vuetify.theme.dark.toString()
        });

    }
}
