























import { MetaInfo } from "vue-meta";
import { Component, Vue } from 'vue-property-decorator';
import AppSideNav from "@/components/AppSideNav.vue";
import AppBottomNav from "@/components/AppBottomNav.vue";
import FabTop from "@/components/FabTop.vue";

@Component ({
	metaInfo(): MetaInfo {
        return {
            title: "Resume",
			titleTemplate: '%s | DM'
        };
    },

	components: {
		AppSideNav,
		AppBottomNav,
		FabTop
	}
})
export default class App extends Vue {
	routes = [
		{ title: 'Home', icon: 'mdi-home-roof', to: '/' },
		// { title: 'Education', icon: 'mdi-school-outline', to: '/education' },
		{ title: 'Experience', icon: 'mdi-briefcase-outline', to: '/experience' },
		{ title: 'Contact', icon: 'mdi-email-variant', to: '/contact' }
	];

	// Is mobile or not
    get mobile(): boolean {
        return this.$vuetify.breakpoint.smAndDown;
	}

	// Is theme dark active?
    get isThemeDark(): boolean {
		return this.$vuetify.theme.dark;
	}

	get backgroundColor(): string {
		return `background-color: ${this.isThemeDark ? '#121212' : '#f4f6f9'}`;
	}
}
