



















import { Component, Vue } from "vue-property-decorator";

@Component
export default class FabTop extends Vue {
    fabTop = false;

    // Displays button if the user has scrolled
    onScroll(e): void {
        if (typeof window === 'undefined') return;
        const top = window.pageYOffset ||   e.target.scrollTop || 0;
        this.fabTop = top > 200;
    }

    // Is mobile or not
    get mobile(): boolean {
        return this.$vuetify.breakpoint.smAndDown;
	}
}
